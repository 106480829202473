/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 90%;
}
#home {
  width: 220px;
  padding: 10px;
  box-sizing: border-box;
  background-image: radial-gradient(50% 50% ellipse, #FFFFFF5E, #FFFFFF00);
}
@media only screen and (max-width: 767px) {
  .downlink {
    width: 50px;
    height: 29px;
    background-size: 50px 29px;
  }
  .vcard {
    width: 100%;
    margin-bottom: 0;
  }
  #services {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #services .meta.auth {
    display: none;
  }
  #view div.seam div.lead {
    bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1%;
  margin-left: 1%;
}
.area .part,
.area > .grid table {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.area .tiny {
  width: 48%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2%;
  margin-left: 2%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96%;
}
.area > .slim .tiny {
  width: 96%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 102%;
  margin-left: -1%;
}
.cb-layout1 div.main div.seam,
.cb-layout2 div.main div.seam,
.cb-layout3 div.main div.seam {
  margin-left: 1%;
  margin-right: 1%;
}
.cb-layout1 div.main div.seam.wide,
.cb-layout2 div.main div.seam.wide,
.cb-layout3 div.main div.seam.wide {
  width: 98%;
}
.cb-layout1 div.main div.seam.slim,
.cb-layout2 div.main div.seam.slim,
.cb-layout3 div.main div.seam.slim {
  width: 48%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #c40d0e;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #c40d0e;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #940a0b;
}
.mese .same {
  background: none;
}
/*# sourceMappingURL=./screen-medium.css.map */